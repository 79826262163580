const VideoPlayerConfig = {
  //https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.PlayerConfiguration
  playerConfig: {
    streaming: {
      alwaysStreamText: true,
      //https://shaka-player-demo.appspot.com/docs/api/tutorial-network-and-buffering-config.html
      rebufferingGoal: 10,
      bufferingGoal: 300,
    },
    //https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.AbrConfiguration
    //   abr: {
    //     defaultBandwidthEstimate: 500000,
    //     //   https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.Restrictions
    //     restrictions: {
    //       minWidth: width,
    //     },
    //     switchInterval: 1,
    //     bandwidthUpgradeTarget: 1,
    //     bandwidthDowngradeTarget: 1,
    //   },
  },
  uiConfig: {
    addBigPlayButton: true,
    addSeekBar: true,
    doubleClickForFullscreen: true,
    enableKeyboardPlaybackControls: true,
    enableFullscreenOnRotation: true,
    fadeDelay: 0,
    //   clearBufferOnQualityChange: false,
    showUnbufferedStart: false,
    //   castReceiverAppId: "iAmTheId",
    //Configuring elements to be displayed on video player control panel
    //https://shaka-player-demo.appspot.com/docs/api/tutorial-ui-customization.html
    controlPanelElements: [
      // "play_pause",
      "time_and_duration",
      "spacer",
      "mute",
      "volume",
      "fullscreen",
      "overflow_menu",
    ],
    overflowMenuButtons: [
      // "cast",
      "quality",
    ],
    seekBarColors: {
      base: "rgba(255, 255, 255, 0.3)",
      buffered: "rgba(255, 255, 255, 0.54)",
      played: "rgb(255, 255, 255)",
    },
    volumeBarColors: {
      base: "rgba(15,76,129,1)",
      level: "rgba(146,137,45,1)",
    },
  },
};
export default VideoPlayerConfig;
