import React from "react";
import { AppBar, Toolbar } from "@material-ui/core/";

function MaterialUIHeader() {
  return (
    <AppBar position="static" style={{ backgroundColor: "#002554" }}>
      <Toolbar>
        <a variant="h6" className="header-title" href="/">
          Dharmaraj Dev
        </a>
        <a
          variant="h6"
          className="header-title header-title-sub"
          href="/portfolio"
        >
          Portfolio
        </a>
        <a
          variant="h6"
          className="header-title header-title-sub"
          href="https://blog.dharmaraj.dev"
        >
          Blog
        </a>
        <a
          variant="h6"
          className="header-title header-title-sub"
          href="mailto:hello@dharmaraj.dev?Subject=Contact%20form"
        >
          Contact
        </a>
      </Toolbar>
    </AppBar>
  );
}
export default MaterialUIHeader;
