import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import "./index.css";
import VideoPlayer from "./VideoPlayer/VideoPlayer";
import Canvas from "./Canvas/Canvas";
import Portfolio from "./Portfolio/Portfolio";
import Error from "./404/Error";

import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

export default class Main extends React.PureComponent {
  // let location = useLocation();
  // const analytics = useAnalytics();
  // useEffect(() => {
  //   // console.log("send page view");
  //   analytics.page();
  // }, [location]);
  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }
  render() {
    return (
      <main>
        <Router history={history}>
          <Switch>
            <Route exact path="/" component={Canvas} />
            <Route exact path="/disney" component={VideoPlayer} />
            <Route exact path="/portfolio" component={Portfolio} />
            <Route component={Error} />
          </Switch>
        </Router>
      </main>
    );
  }
}
