import React from "react";
import "./Canvas.css";
import WebFont from "webfontloader";

const FONT_NAME = "Nothing You Could Do";
class Canvas extends React.Component {
  state = { contactContainer: { visibility: "hidden" } };
  componentDidMount() {
    /**
     * used these two to get this prototype working
     * https://stackoverflow.com/questions/29911143/how-can-i-animate-the-drawing-of-text-on-a-web-page
     * https://blog.cloudboost.io/using-html5-canvas-with-react-ff7d93f5dc76
     */
    const canvas = this.refs.canvas;
    const ctx = canvas.getContext("2d");

    getFont()
      .then(() => {
        return drawLogo(ctx);
      })
      .then((resolveValue) => {
        console.log(resolveValue);
        this.setState(() => {
          return { contactContainer: { display: "block" } };
        });
      })
      .catch((error) => {
        console.log(error);
        return;
      });
  }
  render() {
    return (
      <div className="App">
        <div className="canvas-container">
          <canvas className="canvas" ref="canvas" width={700} height={130}>
            <div className="website-name">Dharmaraj.dev</div>
          </canvas>
        </div>
        <div className="buttonRow">
          {" "}
          <a
            className="contactContainer"
            href="/portfolio"
            style={this.state.contactContainer}
          >
            <div>Portfolio</div>
          </a>
          <a
            className="contactContainer"
            href="https://blog.dharmaraj.dev"
            style={this.state.contactContainer}
          >
            <div>Blog</div>
          </a>
          <a
            className="contactContainer"
            href="mailto:hello@dharmaraj.dev?Subject=Contact%20form"
            style={this.state.contactContainer}
          >
            <div>Contact</div>
          </a>
        </div>
      </div>
    );
  }
}
export default Canvas;

function getFont() {
  return new Promise((resolve) => {
    WebFont.load({
      google: { families: [FONT_NAME] },
      active: resolve,
    });
  });
}

function drawLogo(ctx) {
  var dashLen = 220,
    dashOffset = dashLen,
    speed = 10,
    txt = "Dharmaraj.dev",
    x = 30,
    i = 0;

  ctx.font = `88px "${FONT_NAME}"`;
  ctx.lineWidth = 1;
  ctx.lineJoin = "bevel";
  ctx.globalAlpha = 1;
  ctx.strokeStyle = ctx.fillStyle = "#92892d";
  return new Promise((resolve) => {
    (function loop() {
      ctx.clearRect(x, 0, 60, 150);
      ctx.setLineDash([dashLen - dashOffset, dashOffset - speed]); // create a long dash mask
      dashOffset -= speed; // reduce dash length
      ctx.strokeText(txt[i], x, 90); // stroke letter

      if (dashOffset > 0) requestAnimationFrame(loop);
      // animate
      else {
        ctx.fillText(txt[i], x, 90); // fill final letter
        dashOffset = dashLen; // prep next char
        x += ctx.measureText(txt[i++]).width + ctx.lineWidth * Math.random();
        ctx.setTransform(1, 0, 0, 1, 0, 3 * Math.random()); // random y-delta
        ctx.rotate(Math.random() * 0.005); // random rotation
        if (i < txt.length) {
          requestAnimationFrame(loop);
        } else {
          resolve("You have saved this city!!");
        }
      }
    })();
  });
}
