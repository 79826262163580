const cardData = [
  {
    img: "portfolio-images/streets-ahead-learner.png",
    imgTitle:
      "A variety of designs to spice things up while you match pinyin to their simplified Chinese counterparts",
    title: "Streets Ahead",
    description:
      "Language learning games for people who are not streets behind.",
    url: "https://devpost.com/software/streets-ahead-learner",
  },
  {
    img: "portfolio-images/gif-text-buddy.jpg",
    imgTitle: "Mayday Web Admin Console",
    title: "Gif Text Buddy",
    description:
      "Let the universe find the perfect GIF to send in your message.",
    url: "https://github.com/jjdharmaraj/gif-text-buddy",
  },
  {
    img: "portfolio-images/indy-civic-hackathon-2019.jpg",
    imgTitle: "Indy Civic Hackathon Banner",
    title: "Better Student Plan",
    description:
      "A student portal focused on progress toward graduation and accomodating complex schedule creation.",
    url: "https://indychamber.com/news/6th-annual-civic-hackathon-tackles-barriers-to-economic-mobility/",
  },
  {
    img: "portfolio-images/factom-hackathon-2018.jpg",
    imgTitle: "Team SmArt Share",
    title: "SmArt Share",
    description:
      "Smart contract solution allowing for group purchasing or investing.",
    url: "https://www.factom.com/hackathon/smart-contract-recap/",
  },
  {
    img: "portfolio-images/mayday.png",
    imgTitle: "Mayday Web Admin Console",
    title: "Mayday",
    description: "Join the resistance to end human trafficking.",
    url: "https://devpost.com/software/mayday-j2tr7a",
  },
  {
    img: "portfolio-images/vita-geneome.png",
    imgTitle: "Sample web app for Vita Geneome",
    title: "Vita Geneome",
    description:
      "Help find which micronutrients are most important to include in your diet based on your individual genome.",
    url: "https://devpost.com/software/vita-geneome",
  },
  {
    img: "portfolio-images/team-wordnugget.jpg",
    imgTitle: "The group who built WordNugget",
    title: "WordNugget",
    description:
      "A service that sends parents of infants and toddlers messages about kindergarten readiness skills through text message.",
    url: "https://developer.att.com/blog/chicago-hackathon-recap-disruptive-apps",
  },
  {
    img: "portfolio-images/nasa-dtn-2015.png",
    imgTitle:
      "NASA Tournament Labs (NTL) Micro-Task Challenges Pilot Project Banner",
    title: "Delay/Disruption Tolerant Networking Concept",
    description: "How to communicate with limited or no Internet in space.",
    url: "https://www.nasa.gov/feature/nasa-tournament-labs-ntl-micro-task-challenges-pilot-project",
  },
];
export default cardData;
