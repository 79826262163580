import React from "react";
import MaterialUIHeader from "./../Header/Header";
import twitterAvatar from "./personal_poster.png";

import ReactGA from "react-ga";

import "shaka-player/dist/controls.css";
import "./VideoPlayer.css";
import VideoPlayerConfig from "./VideoPlayerConfig";
const shaka = require("shaka-player/dist/shaka-player.ui.js");

// const posterUri = "https://shaka-player-demo.appspot.com/assets/poster.jpg";
const posterUri = twitterAvatar;
//Creating class component
class VideoPlayer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showVideo: true,
      errorMessage: "Who knows what broke!",
    };

    //Creating reference to store video component on DOM
    this.videoComponent = React.createRef();

    //Creating reference to store video container on DOM
    this.videoContainer = React.createRef();

    //Initializing reference to error handlers
    this.onErrorEvent = this.onErrorEvent.bind(this);
    this.onError = this.onError.bind(this);
  }
  onVideoPress(category, action, videoName, video, player) {
    ReactGA.event({
      category,
      action,
      label: videoName,
      value: video.currentTime,
      transport: "beacon",
    });
    console.log(action);
    //TODO-3: the get stats needs to be put into a logger, not ga
    console.log(player.getStats());
  }

  onErrorEvent(event) {
    // Extract the shaka.util.Error object from the event.
    this.onError(event.detail);
  }

  onError(error) {
    // Log the error.
    console.log(error);
    // console.error("Error code", error.code, "object", error);
    this.setState({
      showVideo: false,
      errorMessage: "I am sorry, but we had a problem loading the video",
    });
  }

  async componentDidMount() {
    //Getting reference to video and video container on DOM
    const video = this.videoComponent.current;
    const videoContainer = this.videoContainer.current;
    // let width = this.videoContainer.current.clientWidth;
    // console.log(width);

    //Initialize shaka player
    let player = new shaka.Player(video);
    player.configure(VideoPlayerConfig.playerConfig);
    //Setting up shaka player UI
    const ui = new shaka.ui.Overlay(player, videoContainer, video);

    ui.configure(VideoPlayerConfig.uiConfig); //configure UI
    ui.getControls();

    // Listen for error events.
    player.addEventListener("error", this.onErrorEvent);
    console.log(shaka.Player.isBrowserSupported());

    // Try to load a manifest.
    // This is an asynchronous process.
    const support = await shaka.Player.probeSupport();
    console.log(support);
    //not actually sure which encoding they use & should probably test for audio as well
    //also not support ts + mux.js
    // let manifestUri =
    //   "https://storage.googleapis.com/darkwing_duck_test1234/audio_personal_test_1_mp3/";
    // let manifestUri =
    //   "https://storage.googleapis.com/darkwing_duck_test1234/personal_test_1_mp4/";
    let manifestUri =
      "https://storage.googleapis.com/darkwing_duck_test1234/personal-test-1-again/";
    if (
      support.manifest.mpd &&
      support.media['video/mp4; codecs="av01.0.01M.08"']
    ) {
      // manifestUri += "audio.mpd";
      manifestUri += "custom_dash_name.mpd";
      this.setState({ showVideo: true });
    } else if (
      support.manifest.m3u8 &&
      support.media['video/mp4; codecs="av01.0.01M.08"']
    ) {
      //TODO-2: solve the safari bug
      // manifestUri += "audio.m3u8";
      manifestUri += "custom_hls_name.m3u8";
      this.setState({ showVideo: true });
    } else {
      console.log("you have failed this city");
      this.setState({
        showVideo: false,
        errorMessage:
          "I am sorry, but we cannot support your browser at this time",
      });
    }
    //This will be used later when you save the previous progress time in a DB
    let startTime = null;
    if (this.state.showVideo) {
      player
        .load(manifestUri, startTime)
        .then(() => {
          // This runs if the asynchronous load is successful.
          console.log("The video has now been loaded!");
        })
        .catch(this.onError); // onError is executed if the asynchronous load fails.
    }
    /**
     * TODO-0:
     * https://www.w3schools.com/tags/ref_av_dom.asp
     * https://www.w3schools.com/jsref/dom_obj_event.asp
     *
     * https://github.com/google/shaka-player/issues/416
     * https://shaka-player-demo.appspot.com/docs/api/shaka.Player.html
     * https://shaka-player-demo.appspot.com/docs/api/shaka.extern.html#.Stats
     *
     * https://github.com/react-ga/react-ga
     * https://mtm.dev/google-analytics-react-router
     *
     * Some of these states are being called when they shouldn't be. Perhaps we need to call
     * the events on button click & keyboard short cut.
     *
     * Also the value doesn't seem to be showing up in GA.
     *
     * Take a look at VideoAnalytics.md for the states we need in GA.
     *
     */
    const videoName = "Fake Video Name";
    video.oncanplay = () =>
      this.onVideoPress(
        "Videos",
        "video is ready to start playing",
        videoName,
        video,
        player
      );

    video.onplaying = () =>
      this.onVideoPress(
        "Videos",
        "playing after having been paused or stopped for buffering",
        videoName,
        video,
        player
      );
    video.onpause = () =>
      this.onVideoPress(
        "Videos",
        "video has been paused",
        videoName,
        video,
        player
      );

    video.onseeking = () =>
      this.onVideoPress(
        "Videos",
        "user starts moving/skipping to a new position in the video",
        videoName,
        video,
        player
      );
    video.onseeked = () =>
      this.onVideoPress(
        "Videos",
        "user is finished moving/skipping to a new position in the video",
        videoName,
        video,
        player
      );

    video.onvolumechange = () =>
      this.onVideoPress(
        "Videos",
        "volume of a video has been changed",
        videoName,
        video,
        player
      );
    video.addEventListener(
      "muted",
      this.onVideoPress(
        "Videos",
        "video has been muted",
        videoName,
        video,
        player
      )
    );

    video.addEventListener(
      "fullscreenchange",
      this.onVideoPress(
        "Videos",
        "page is viewed in fullscreen mode",
        videoName,
        video,
        player
      )
    );
    video.addEventListener(
      "fullscreenerror",
      this.onVideoPress("Videos", "Fullscreen denied", videoName, video, player)
    );

    video.onratechange = () =>
      this.onVideoPress(
        "Videos",
        "playing speed of the video is changed",
        videoName,
        video,
        player
      );

    video.onwaiting = () =>
      this.onVideoPress(
        "Videos",
        "video stops because it needs to buffer the next frame",
        videoName,
        video,
        player
      );
    video.oncanplaythrough = () =>
      this.onVideoPress(
        "Videos",
        "video can be played all the way through, without stopping",
        videoName,
        video,
        player
      );

    video.addEventListener(
      "ended",
      this.onVideoPress(
        "Videos",
        "video playback has ended",
        videoName,
        video,
        player
      )
    );
    // this one literally fires all the time
    // video.ontimeupdate = function () {
    //   console.log(player.getStats());
    //   console.log(video.currentTime);
    // };
    /**
     * This analytics doesn't work yet,
     * possibly try building a custom plugin first to make sure it is right then Google Analytics
     * 1. https://www.youtube.com/watch?v=C_1ced3l9cU & https://getanalytics.io/plugins/google-analytics/
     * 2. Video element events: https://github.com/google/shaka-player/issues/416
     * 3. Other events: https://github.com/google/shaka-player/issues/2230
     * 4. get stats
     */
    // const analytics = useAnalytics();
    // video.addEventListener(
    //   "playing",
    //   analytics.track("playedVideo", {
    //     category: "Videos",
    //     label: "M13 Video",
    //     value: 42,
    //   })
    // );
  }
  render() {
    /*
		Returning video with a container. Remember, when setting up shaka player with custom UI, you must
		add your video component inside a container
		The container will be used by shaka player to add your customized UI for the player
    */
    const showVideo = this.state.showVideo;
    const errorMessage = this.state.errorMessage;
    if (showVideo) {
      return (
        <div>
          <MaterialUIHeader />
          <div className="App">
            <div className="canvas-container">
              <div className="video-container" ref={this.videoContainer}>
                <video
                  className="shaka-video"
                  ref={this.videoComponent}
                  poster={posterUri}
                />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <MaterialUIHeader />
          <div className="App">
            <div className="canvas-container video-error-text">
              {errorMessage}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default VideoPlayer;
