import React from "react";
import "./Error.css";
import MaterialUIHeader from "./../Header/Header";

function Error() {
  return (
    <div>
      <MaterialUIHeader />
      <div className="App">
        <header className="App-header">
          <div className="error-text">Oops! Page not found!</div>
        </header>
      </div>
    </div>
  );
}

export default Error;
