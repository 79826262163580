import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Button,
  Typography,
  CardMedia,
  CardActions,
  CardActionArea,
  Grow,
} from "@material-ui/core/";
import cardData from "./PortfolioData";
import "./Portfolio.css";
import MaterialUIHeader from "./../Header/Header";

export default function Portfolio() {
  return (
    <div>
      <MaterialUIHeader />
      <div className="Portfolio-header">
        <Grid
          container
          spacing={2}
          direction="row"
          justify="center"
          alignItems="stretch"
          className="grid"
          style={{ width: "100%" }}
        >
          {cardData.map((card, i) => (
            <Grid item xs={12} sm={6} md={4} key={cardData.indexOf(card)}>
              {/* TODO: the images sometimes take too long to load in
              production compared to the Grow animation but increasing
              the transition delay is also a bad experience */}
              <Grow
                in={true}
                style={{
                  transitionDelay: i * 500 + "ms",
                }}
              >
                <Card className="card">
                  <CardActionArea className="cardActionArea">
                    <CardMedia
                      className="media"
                      image={`${card.img}`}
                      title={`${card.imgTitle}`}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        {`${card.title}`}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {`${card.description}`}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                    {" "}
                    {card.url ? (
                      <Button
                        variant="contained"
                        color="primary"
                        href={card.url}
                        target="blank"
                      >
                        Learn More
                      </Button>
                    ) : (
                      <span></span>
                    )}
                  </CardActions>
                </Card>
              </Grow>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
